<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">{{ wedding.man.surname }} & {{ wedding.woman.surname }} </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio ref="audio" :file="music.file" :loop="music.loop" :autoplay="music.autoplay"/>
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro"/>

    <div class="bg-white agw-content" :style="{ 'margin-top' : `${$root.hero.height}px` }">
      <AGWIntro id="intro"/>

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan"/>
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes"/>
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="pad-y-60"/>
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.6148753899583!2d115.14570941478512!3d-8.82220259366448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd25b2ea939fc33%3A0x3504aac0142970e6!2sJl.%20Grand%20Ungasan%20No.7%2C%20Ungasan%2C%20Kec.%20Kuta%20Sel.%2C%20Kabupaten%20Badung%2C%20Bali%2080361!5e0!3m2!1sen!2sid!4v1630840918993!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//Jl.+Grand+Ungasan+No.7,+Ungasan,+Kec.+Kuta+Sel.,+Kabupaten+Badung,+Bali+80361/@-8.8222026,115.1457094,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2dd25b2ea939fc33:0x3504aac0142970e6!2m2!1d115.1478981!2d-8.8222026"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ 'height' : `${$root.dividerHeight}px` }"></div>

    <Footer />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height"/>
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 = 'https://ik.imagekit.io/c3remonyku/eva-leni/IMG_6665.jpg?updatedAt=1630840989537&tr=w-1024'
const ImageHero2 = 'https://ik.imagekit.io/c3remonyku/eva-leni/IMG_6765.jpg?updatedAt=1630840991272&tr=w-1024'
const ImageHero3 = 'https://ik.imagekit.io/c3remonyku/eva-leni/IMG_6606.jpg?updatedAt=1630840988525&tr=w-1024'

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from '@/components/Himbauan.vue';
import Attendance from "@/components/Attendance";
import Footer from "@/components/Footer/Ceremonyku.vue";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    Footer,
  },
  data() {
    return {
      title: 'Pawiwahan',
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: 'I Putu Eva Darmadhi',
          surname: 'Eva',
          parents: 'I Wayan Tama & Ni Made Sutriani',
          description: 'Putra pertama',
          address: 'Lingkungan Perum Grand Ungasan. Br. Bakung Sari, Ungasan',
          photo: 'https://ik.imagekit.io/c3remonyku/eva-leni/IMG_6772.jpg?updatedAt=1630840993257&tr=w-671,h-842,fo-custom,cm-extract',
        },
        woman: {
          name: 'Ni Luh Leniati',
          surname: 'Leni',
          parents: 'I Nyoman Lendra & Ni Wayan Yuliati',
          address: 'Jl. Nusa Dua Gang Pengkol Liar. Br. Kangin, Ungasan',
          description: 'Putri pertama',
          photo: 'https://ik.imagekit.io/c3remonyku/eva-leni/IMG_6778.jpg?updatedAt=1630840994102&tr=w-722,h-902,fo-custom,cm-extract',
        },
      },
      location: "Lingkungan Perum Grand Ungasan. Br. Bakung Sari, Ungasan, Kec. Kuta Selatan, Badung - Bali",
      time: {
        start: this.$moment("2021-09-17 13:00"),
        end: "Selesai"
      },
      mepandes: [
        "Ni Kadek Pebri Mulia Dewi",
        "I Ketut Hendrawan"
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
